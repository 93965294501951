import { useEffect, type FC, type ReactNode } from 'react';

import { HorizontalLayout } from './horizontal-layout';
import { useAuth } from 'src/hooks/use-auth';
import { useDispatch } from 'src/store';
import { chamberManagementAPI } from 'src/api/chambers-management';
import { slice } from 'src/slices/smb';

interface LayoutProps {
  children?: ReactNode;
}

const useMyOrg = async () => {
  const { user } = useAuth();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchOrg = async () => {
      try {
        const smb = await chamberManagementAPI.getOrganization(user?.org_id as string);
        dispatch(slice.actions.setMyOrg(smb));
      } catch (error) {
        console.error(error);
      }
    };

    fetchOrg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.smb_id]);
};

export const Layout: FC<LayoutProps> = (props) => {
  useMyOrg();
  return <HorizontalLayout {...props} />;
};
