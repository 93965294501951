export const paths = {
  index: '/',
  internal: '/internal',
  contact: '/contact',
  auth: {
    login: '/auth/login',
    forgotPassword: '/auth/forgot-password',
    resetPassword: '/auth/reset-password',
    signup: '/auth/signup',
  },
  workspace: {
    index: '/workspace',
    chat: '/workspace/chat',
    broadcast: '/workspace/chat/broadcast',
    memory: {
      index: '/workspace/memory',
      members: '/workspace/memory/members',
      edit: '/workspace/memory/members/edit'
    },
    insights: '/workspace/insights',
    introductions: '/workspace/introductions',
    settings: '/workspace/settings',
    skills: {
      index: '/workspace/skills',
      requests: '/workspace/skills/requests',
      requestDetails: '/workspace/skills/requests/:id',
    },
    welcome: {
      index: '/welcome-chamber',
      onboarding: '/welcome-chamber/onboarding',
      uploadMembers: '/welcome-chamber/upload-members',
      uploadMemory: '/welcome-chamber/upload-memory',
    },
    news: {
      index: '/workspace/news',
    }
  },
  business: {
    index: '/business',
    insights: '/business/insights',
    memory: '/business/memory',
    chat: '/business/chat',
    profile: '/business/profile',
    news: {
      index: '/business/news',
      details: '/business/news/details',
    }, 
    settings: '/business/settings',
    settingsBusinessDetails: '/business/memory/business-details',
    settingsProductsServices: '/business/memory/products-services',
    settingsContactSocial: '/business/memory/contact-social',
    settingsMembers: '/business/memory/members',
    settingsPricing: '/business/settings/pricing',
    topCompetitors: '/business/top-competitors',
    introductions: '/business/introductions',
    introductionDetails: '/business/introductions/:id',
    disruptors: '/business/disruptors',
    skills: {
      index: '/business/skills',
      requests: '/business/skills/requests',
      requestDetails: '/business/skills/requests/:id',
    },
    welcome: {
      index: '/welcome-business',
      onboarding: '/welcome-business/onboarding',
      userRole: '/welcome-business/user-role',
      profile: '/welcome-business/profile',
      offersAndRequests: '/welcome-business/offers-and-requests',
    },
  },
  dashboard: {
    index: '/dashboard',
    chat: '/dashboard/chat',
    welcome: '/dashboard/welcome',
    welcomeSMB: '/dashboard/welcome-smb',
    uploadFirstTime: '/dashboard/upload-businesses-first-time',
    emailNotVerified: '/dashboard/email-not-verified',
    v2Members: {
      index: '/dashboard/members',
      seller: '/dashboard/members/seller',
      demandSummary: '/dashboard/members/demand/:demandId/summary',
      chat: '/dashboard/members/demand/:demandId/chat',
      schedule: '/dashboard/members/demand/:demandId/schedule',
      meetingDetails: '/dashboard/members/demand/:demandId/meeting-details',
      supplierDemandStatus: '/dashboard/members/supplier-demand-status/:id',
      supplierOfferStatus: '/dashboard/members/supplier-offer-status',
    },
    enterprise: {
      suppliers: '/dashboard/enterprise/suppliers',
      supplier: '/dashboard/enterprise/suppliers/:id',
      settings: '/dashboard/enterprise/settings',
      insights: '/dashboard/enterprise/insights',
    },
    chambers: {
      index: '/dashboard/chambers',
      members: '/dashboard/chambers/smbs',
      upload: '/dashboard/chambers/upload-smbs',
      knowledgeBase: '/dashboard/chambers/knowledge-base',
      aiChat: '/dashboard/chambers/ai-chat',
      membersManagement: '/dashboard/chambers/settings',
      businessMatching: '/dashboard/chambers/business-matching',
      approveEmail: '/dashboard/chambers/approve-email',
      business: {
        index: '/dashboard/chambers/smbs/:id',
      },
    },
    organizationIndex: {
      index: '/dashboard/organization',
      attendees: '/dashboard/organization/attendees',
      suppliers: '/dashboard/organization/suppliers',
      marketInterests: '/dashboard/organization/market-interests',
      organizationAgenda: '/dashboard/organization/organization-agenda',
    },
    suppliers: {
      index: '/dashboard/suppliers',
      eventPlanner: '/dashboard/suppliers/event-planner',
    },
    contractManufacturers: {
      index: '/dashboard/contract-manufacturers',
      eventPlanner: '/dashboard/contract-manufacturers/event-planner',
    },
    smbs: {
      index: '/dashboard/smbs',
      details: '/dashboard/smbs/:id',
      connections: '/dashboard/smbs/connections',
      claim: '/dashboard/smbs/claim',
    },
  },
  docs: 'https://google.com',
  notAuthorized: '/401',
  notFound: '/404',
  serverError: '/500',
  sorry: '/sorry',
  newPassword: '/change-password',
  signup: '/signup',
  signupSeller: '/signup-seller',
  authRedirect: 'auth-redirect',
};
