import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet, Navigate } from 'react-router-dom';

import { Layout as MembersLayout } from 'src/layouts/v2-members';
import { AuthGuard } from 'src/guards/auth-guard';
import { paths } from 'src/paths';

const Index = lazy(() => import('src/pages/v2-members/index'));
const ChatPage = lazy(() => import('src/pages/v2-members/chat'));
const BusinessProfile = lazy(() => import('src/pages/v2-members/business-profile'));
const SettingsPage = lazy(() => import('src/pages/v2-members/settings'));
const NotificationsPage = lazy(() => import('src/pages/v2-members/notifications'));
const RequestsPage = lazy(() => import('src/pages/v2-members/skills/requests'));
const RequestDetailsPage = lazy(
  () => import('src/pages/v2-members/skills/requests/request-details')
);
const DiscoverPage = lazy(() => import('src/pages/_exp/discover/discover'));
const InsightsPage = lazy(() => import('src/pages/v2-members/insights'));
const IntroductionsDetailsPage = lazy(
  () => import('src/pages/v2-members/introductions/introduction-details')
);
const IntroductionsPage = lazy(() => import('src/pages/v2-members/introductions'));
const TopCompetitorsPage = lazy(() => import('src/pages/v2-members/top-competitors'));
const DisruptorsPage = lazy(() => import('src/pages/v2-members/disruptors'));
const PaymentSuccess = lazy(() => import('src/pages/common/v2/settings/payment/success'));
const PaymentCanceled = lazy(() => import('src/pages/common/v2/settings/payment/canceled'));
const BusinessDetailsPage = lazy(() => import('src/pages/v2-members/settings/business-details'));
const ProductsServicesPage = lazy(() => import('src/pages/v2-members/settings/products-services'));
const ContactSocialPage = lazy(() => import('src/pages/v2-members/settings/contact-social'));
const MembersPage = lazy(() => import('src/pages/v2-members/settings/members'));
const PricingPage = lazy(() => import('src/pages/v2-members/settings/pricing'));
const NewsFeedPage = lazy(() => import('src/pages/v2-members/news/feed'));
const NewsDetailsPage = lazy(() => import('src/pages/v2-members/news/news-details'));
const MemoryPage = lazy(() => import('src/pages/v2-members/memory'));

export const V2MembersRoutes: RouteObject[] = [
  {
    path: 'business',
    element: (
      <AuthGuard>
        <MembersLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </MembersLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: 'chat',
        element: <ChatPage />,
      },
      {
        path: 'profile',
        element: <BusinessProfile />,
      },
      {
        path: 'memory',
        element: (
          <MemoryPage>
            <Outlet />
          </MemoryPage>
        ),
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={paths.business.settingsBusinessDetails}
                replace
              />
            ),
          },
          {
            path: 'business-details',
            element: <BusinessDetailsPage />,
          },
          {
            path: 'products-services',
            element: <ProductsServicesPage />,
          },
          {
            path: 'contact-social',
            element: <ContactSocialPage />,
          },
          {
            path: 'members',
            element: <MembersPage />,
          },
        ],
      },
      {
        path: 'news',
        children: [
          {
            index: true,
            element: <NewsFeedPage />,
          },
          {
            path: 'details',
            element: <NewsDetailsPage />
          }
        ],
      },
      {
        path: 'settings',
        element: (
          <SettingsPage>
            <Outlet />
          </SettingsPage>
        ),
        children: [
          {
            index: true,
            element: (
              <Navigate
                to={paths.business.settingsPricing}
                replace
              />
            ),
          },
          {
            path: 'pricing',
            element: <PricingPage />,
          },
          {
            path: 'payment/success',
            element: <PaymentSuccess />,
          },
          {
            path: 'payment/cancel',
            element: <PaymentCanceled />,
          },
        ],
      },
      {
        path: 'notifications',
        element: <NotificationsPage />,
      },
      {
        path: 'discover',
        element: <DiscoverPage />,
      },
      {
        path: 'insights',
        element: <InsightsPage />,
      },
      {
        path: 'top-competitors',
        element: <TopCompetitorsPage />,
      },
      {
        path: 'introductions/:id',
        element: <IntroductionsDetailsPage />,
      },
      {
        path: 'introductions',
        element: <IntroductionsPage />,
      },
      {
        path: 'disruptors',
        element: <DisruptorsPage />,
      },
      // {
      //   path: 'canvas',
      //   element: <CanvasPage />,
      // },
      {
        path: 'skills',
        children: [
          {
            path: 'requests',
            element: <RequestsPage />,
          },
          {
            path: 'requests/:id',
            element: <RequestDetailsPage />,
          },
        ],
      },
    ],
  },
];
