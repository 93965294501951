import axios, { type AxiosInstance } from 'axios';
import { STORAGE_KEY } from 'src/contexts/auth/auth-provider';
import { checkForExpiredToken } from 'src/utils/jwt';
import { paths } from 'src/paths';

interface AuthAxiosConfig {
  baseUrl: string;
  paramsSerializer?: {
    indexes: null;
    serialize: (params: Record<string, any>) => string;
  };
}

class AuthAxios {
  protected axiosInstance: AxiosInstance;

  constructor({ baseUrl, paramsSerializer }: AuthAxiosConfig) {
    this.axiosInstance = axios.create({
      baseURL: `${baseUrl}/v1`,
      paramsSerializer: paramsSerializer || {
        indexes: null,
        serialize: (params) => {
          return Object.entries(params)
            .map(([key, value]) => {
              if (Array.isArray(value)) {
                return value
                  .map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
                  .join('&');
              }
              return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
            })
            .join('&');
        },
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.injectHeaders(this.axiosInstance);
    this.injectResponseInterceptor(this.axiosInstance);
  }

  private checkExpiredToken(token: string) {
    checkForExpiredToken(token);
  }

  private injectHeaders(axios: AxiosInstance) {
    axios.interceptors.request.use(
      (config) => {
        const token = sessionStorage.getItem(STORAGE_KEY);
        if (token) {
          this.checkExpiredToken(token);
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  private injectResponseInterceptor(axios: AxiosInstance) {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 402) {
          // Redirect to pricing page when subscription is required
          window.location.href = paths.business.settingsPricing;
        }
        return Promise.reject(error);
      }
    );
  }
}

export default AuthAxios;