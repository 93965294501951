import { ThemeProvider } from '@lobehub/ui';
import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { AuthSmbGuard } from 'src/guards/user-role-permissions';
import PreFetch from 'src/pages/v2-members/welcome-intro/pre-fetch';

const AIWelcomeIntroPage = lazy(() => import('src/pages/v2-members/welcome-intro'));
const WelcomeIntroOnboardingPage = lazy(
  () => import('src/pages/v2-members/welcome-intro/onboarding')
);
const UserRolePage = lazy(() => import('src/pages/v2-members/welcome-intro/user-role'));
const ProfilePage = lazy(() => import('src/pages/v2-members/welcome-intro/profile'));
const ServicesAndNeedsPage = lazy(
  () => import('src/pages/v2-members/welcome-intro/services-and-needs')
);

export const welcomeBusinessRoutes: RouteObject[] = [
  {
    path: 'welcome-business',
    element: (
      <ThemeProvider
        customToken={(theme) => ({
          fontFamily: 'Arial, sans-serif',
        })}
        style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
      >
        <AuthSmbGuard>
          <PreFetch>
            <Suspense>
              <Outlet />
            </Suspense>
          </PreFetch>
        </AuthSmbGuard>
      </ThemeProvider>
    ),
    children: [
      {
        index: true,
        element: <AIWelcomeIntroPage />,
      },
      {
        path: 'onboarding',
        element: <WelcomeIntroOnboardingPage />,
      },
      {
        path: 'user-role',
        element: <UserRolePage />,
      },
      {
        path: 'profile',
        element: <ProfilePage />,
      },
      {
        path: 'offers-and-requests',
        element: <ServicesAndNeedsPage />,
      },
    ],
  },
];
