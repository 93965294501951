import { lazy, Suspense } from 'react';
import type { RouteObject } from 'react-router';
import { Outlet } from 'react-router-dom';

import { Layout as ChamberLayout } from 'src/layouts/v2-chambers';
import { AuthChamberGuard } from 'src/guards/user-role-permissions';

const Index = lazy(() => import('src/pages/v2-chambers/index'));
const ChatPage = lazy(() => import('src/pages/v2-chambers/chat'));
const MemoryPage = lazy(() => import('src/pages/v2-chambers/memory'));
const BusinessesPage = lazy(() => import('src/pages/v2-chambers/business'));
const InsightsPage = lazy(() => import('src/pages/v2-chambers/insights'));
const SkillsPage = lazy(() => import('src/pages/v2-chambers/skills'));
const SettingsPage = lazy(() => import('src/pages/v2-chambers/settings'));
const RequestsPage = lazy(() => import('src/pages/v2-chambers/skills/requests'));
const RequestDetailsPage = lazy(
  () => import('src/pages/v2-chambers/skills/requests/request-details')
);
const BusinessProfilePage = lazy(() => import('src/pages/v2-chambers/business/profile'));
const IntroductionsPage = lazy(() => import('src/pages/v2-chambers/introductions'));
const EditPage = lazy(() => import('src/pages/v2-chambers/business/edit-profile'));
const PaymentSuccess = lazy(() => import('src/pages/common/v2/settings/payment/success'));
const PaymentCanceled = lazy(() => import('src/pages/common/v2/settings/payment/canceled'));
const NewsFeedPage = lazy(() => import('src/pages/v2-chambers/news-feed/feed'));
const NewsDetailsPage = lazy(() => import('src/pages/v2-chambers/news-feed/news-details'));

export const V2ChambersRoutes: RouteObject[] = [
  {
    path: 'workspace',
    element: (
      <AuthChamberGuard>
        <ChamberLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </ChamberLayout>
      </AuthChamberGuard>
    ),
    children: [
      {
        index: true,
        element: <Index />,
      },
      {
        path: 'chat/*',
        element: <ChatPage />,
      },
      {
        path: 'memory',
        children: [
          {
            index: true,
            element: <MemoryPage />,
          },
          {
            path: 'members',
            children: [
              {
                index: true,
                element: <BusinessesPage />,
              },
              {
                path: ':id',
                element: <BusinessProfilePage />,
              },
              {
                path: 'edit/:id',
                element: <EditPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'insights',
        element: <InsightsPage />,
      },
      {
        path: 'introductions',
        element: <IntroductionsPage />,
      },
      {
        path: 'news',
        children: [
          {
            index: true,
            element: <NewsFeedPage />,
          },
          {
            path: ':id',
            element: <NewsDetailsPage />
          },
        ]
      },
      {
        path: 'skills',
        children: [
          {
            index: true,
            element: <SkillsPage />,
          },
          {
            path: 'requests',
            element: <RequestsPage />,
          },
          {
            path: 'requests/:id',
            element: <RequestDetailsPage />,
          },
        ],
      },
      {
        path: 'settings',
        children: [
          {
            index: true,
            element: <SettingsPage />,
          },
          {
            path: 'payment/success',
            element: <PaymentSuccess />,
          },
          {
            path: 'payment/cancel',
            element: <PaymentCanceled />,
          },
        ],
      },
    ],
  },
];
