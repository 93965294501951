import { InsightCounters } from 'src/sections/v2-chambers/insights/types';
import AuthAxios from '../auth-axios';
import { VITE_API_LEVELPLANE } from 'src/config';
import { APIResultListFast } from 'src/types/api';
import { ProfileMember, CreateMemberForm } from 'src/types/chamber-members';
import { BusinessConnectionsSummary, Organization } from 'src/types/organization';

interface EmailData {
  email: string;
  is_campaign_sender: boolean;
  is_campaign_sender_verified: string;
}

class ChamberManagementAPI extends AuthAxios {
  constructor() {
    super({ baseUrl: VITE_API_LEVELPLANE });
  }

  async getOrganization(orgId: string): Promise<Organization> {
    try {
      const { data } = await this.axiosInstance.get(`/organizations/${orgId}/`);
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getChamberMembers(
    orgId: string,
    pagination: { page: number; size: number }
  ): Promise<APIResultListFast<ProfileMember>> {
    try {
      const { data } = await this.axiosInstance.get(`/organizations/${orgId}/users/`, {
        params: {
          page: pagination.page,
          size: pagination.size,
        },
      });
      return Promise.resolve(data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createMember(form: CreateMemberForm, orgId: string): Promise<void> {
    try {
      await this.axiosInstance.post(`/profiles`, {
        org_id: orgId,
        first_name: form.first_name,
        last_name: form.last_name,
        emails: [form.email],
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteMember(orgId: string, userId: string): Promise<void> {
    try {
      await this.axiosInstance.delete(`/profiles/${userId}`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async uploadLogo(orgId: string, file: File): Promise<string> {
    try {
      const formData = new FormData();
      formData.append('document', file);

      const response = await this.axiosInstance.post(`/organizations/${orgId}/document`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Extract the file_url from the response
      const fileUrl = response.data.file_url;

      // Ensure the fileUrl starts with 's3://'
      if (fileUrl.startsWith('s3://')) {
        // Extract the bucket name and the file path
        const bucketName = fileUrl.split('/')[2]; // lp-backend-org-documents-675647732323-dev
        const filePath = fileUrl.split('/').slice(3).join('/'); // c58dbf6f-32aa-4b79-bffa-78aa33ca7447/logo_test2.png

        // Construct the new https URL
        const logoUrl = `https://${bucketName}.s3.amazonaws.com/${filePath}`;

        // Patch the organization with the new logo URL
        await this.axiosInstance.patch(`/organizations/${orgId}/`, {
          logo: logoUrl,
        });
        return logoUrl;
      } else {
        throw new Error('Invalid file URL format');
      }
    } catch (error) {
      console.error('Error uploading logo:', error);
      throw error;
    }
  }

  async deleteSMB(smbId: string) {
    try {
      await this.axiosInstance.delete(`smbs/${smbId}/`);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteBulkSMB(ids: string[]) {
    try {
      const idsString = ids.join(',');
      await this.axiosInstance.delete(`/v1/smbs/${idsString}/delete`);
    } catch (error) {
      console.error(error);
    }
  }

  async uploadFile(orgId: string, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('document', file);
    const response = await this.axiosInstance.post(
      `/organizations/${orgId}/upload_document`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    console.log(JSON.stringify(response));
  }

  async deleteFile(orgId: string, id: string): Promise<void> {
    const response = await this.axiosInstance.delete(`/organizations/${orgId}/files/${id}/`);
  }

  async getFiles(orgId: string) {
    const response = await this.axiosInstance.get(`/organizations/${orgId}/files/`);
    console.log(response);
    return response;
  }

  async downloadFile(url: string): Promise<void> {
    const response = await this.axiosInstance.get(url);
  }

  async updateOrganization(org_id: string, organization: Partial<Organization>): Promise<void> {
    try {
      await this.axiosInstance.patch(`/organizations/${org_id}/`, {
        ...organization,
      });
    } catch (error) {
      console.error('Failed to update email:', error);
      throw error;
    }
  }

  async updateApprovalStatus(smb_id: string, organization: Partial<ProfileMember>): Promise<void> {
    try {
      await this.axiosInstance.patch(`/profiles/${smb_id}`, {
        ...organization,
      });
    } catch (error) {
      console.error('Failed to approve request', error);
      throw error;
    }
  }

  async getBusinessConnectionsSummary(orgId: string): Promise<InsightCounters> {
    try {
      const { data } = await this.axiosInstance.get(
        `/associated_requests/org/${orgId}/business-connections/summary`
      );
      return data
    } catch (error) {
      console.error('Failed to approve request', error);
      throw error;
    }
  }

}

export const chamberManagementAPI = new ChamberManagementAPI();
