import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import 'src/global.css';
import 'src/locales/i18n';

import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

import { RTL } from 'src/components/rtl';
import { SettingsDrawer } from 'src/components/settings/settings-drawer';
import { Toaster } from 'src/components/toaster';
import { SettingsConsumer, SettingsProvider } from 'src/contexts/settings';
import { gtmConfig } from 'src/config';
import { useNprogress } from 'src/hooks/use-nprogress';
import { useAnalytics } from 'src/hooks/use-analytics';
import { routes } from 'src/routes';
import { store } from 'src/store';
import { createTheme } from 'src/theme';
// eslint-disable-next-line import/no-unresolved
import { Analytics } from '@vercel/analytics/react';
import { AuthConsumer, AuthProvider } from './contexts/auth';
import { SplashScreen } from './components/splash-screen';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DrawerProvider } from './contexts/drawer';
import { ConfigProvider } from 'antd';

Chart.register(ArcElement, Tooltip, Legend);
const queryClient = new QueryClient();

export const App: FC = () => {
  useAnalytics(gtmConfig);
  useNprogress();

  const element = useRoutes(routes);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Inter", Arial, sans-serif',
          fontSize: 14,
          colorText: '#676767',
          colorPrimary: '#58A5FF',
          colorPrimaryText: '#58A5FF',
        },
      }}
    >
      <QueryClientProvider client={queryClient}>
        {/* <LobeHubThemeProvider
          customToken={(theme) => ({
            fontFamily: 'Arial, sans-serif',
          })}
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
        > */}
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DrawerProvider>
              <AuthProvider>
                <AuthConsumer>
                  {(auth) => (
                    <SettingsProvider>
                      <SettingsConsumer>
                        {(settings) => {
                          // Prevent theme flicker when restoring custom settings from browser storage
                          if (!settings.isInitialized) {
                            //return null;
                          }

                          const theme = createTheme({
                            colorPreset: settings.colorPreset,
                            contrast: settings.contrast,
                            direction: settings.direction,
                            paletteMode: settings.paletteMode,
                            responsiveFontSizes: settings.responsiveFontSizes,
                          });

                          // Prevent guards from redirecting
                          const showSlashScreen = !auth.isInitialized;

                          return (
                            <ThemeProvider theme={theme}>
                              <Helmet>
                                <meta
                                  name="color-scheme"
                                  content={settings.paletteMode}
                                />
                                <meta
                                  name="theme-color"
                                  content={theme.palette.neutral[900]}
                                />
                              </Helmet>
                              <Analytics />
                              <RTL direction={settings.direction}>
                                <CssBaseline />
                                {showSlashScreen ? (
                                  <SplashScreen />
                                ) : (
                                  <>
                                    {element}
                                    <SettingsDrawer
                                      canReset={settings.isCustom}
                                      onClose={settings.handleDrawerClose}
                                      onReset={settings.handleReset}
                                      onUpdate={settings.handleUpdate}
                                      open={settings.openDrawer}
                                      values={{
                                        colorPreset: settings.colorPreset,
                                        contrast: settings.contrast,
                                        direction: settings.direction,
                                        paletteMode: settings.paletteMode,
                                        responsiveFontSizes: settings.responsiveFontSizes,
                                        stretch: settings.stretch,
                                        layout: settings.layout,
                                        navColor: settings.navColor,
                                      }}
                                    />
                                  </>
                                )}
                                <Toaster />
                              </RTL>
                            </ThemeProvider>
                          );
                        }}
                      </SettingsConsumer>
                    </SettingsProvider>
                  )}
                </AuthConsumer>
              </AuthProvider>
            </DrawerProvider>
          </LocalizationProvider>
        </ReduxProvider>
        {/* </LobeHubThemeProvider> */}
      </QueryClientProvider>
    </ConfigProvider>
  );
};
